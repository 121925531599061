@import '../../styles/variables.scss';

.flagsContainer {
    background: $bg-color-primary;
    border-radius: 5px;
    padding: 1rem 0;
    margin-bottom: 5rem;
}

.createFlagFab {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
}

.createLoader {
    position: absolute;
}