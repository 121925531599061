@import '../../styles/variables.scss';

.homeContainer {
    background: $bg-color-primary;
    border-radius: 5px;
    padding: 1rem 0;
    margin-top: 2rem;
}

.moduleSection {
    display: flex;
    width: 50%;
    margin-bottom: 1rem;

    .chip {
        margin-left: 4px !important;
    }
}

@media only screen and (max-width: 600px) {
    .moduleSection {
        width: 100%;
    }
}