// General
$bg-color-primary: #ffffff !default;
$text-color-dark: #000000 !default;
$text-color-inverse: #ffffff !default;
$fab-linear-gradient: linear-gradient(1.43deg, #E1A286 -2.09%, #AB77B7 45.33%, #625BAE 99.02%);

// Thumbnail
$thumbnail-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.35) !default;
$thumbnail-box-shadow-hover: 0px 0px 8px 0px rgba(0, 0, 0, 0.55) !default;

