@import "../../styles/variables.scss";

.loginPage {
    min-height: 100vh;
    padding: 0.5rem;
}

.loginContainer {
    background: $bg-color-primary;
    padding: 2rem 0;
    border-radius: 10px;
}

.logo {
    width: 10.5rem;
    margin: 0 auto;
    padding-bottom: 2rem;
}

.forgotPass {
    display: flex;
    justify-content: center;
}