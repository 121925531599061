@import '../../styles/variables.scss';

.generateReportFab {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    background: $fab-linear-gradient;
}

.fabLoader {
    position: absolute;
}