.drawerList {
    min-width: 260px;
    height: 100%;
}

.logoWrapper {
    display: flex;
}

.logo {
    width: 100px;
}